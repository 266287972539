<template>
  <component :is="organisationData === undefined ? 'div' : 'b-card'">

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="organisationData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching organisation data
      </h4>
      <div class="alert-body">
        No organisation found with this organisation id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'apps-organisations'}"
        >
          Organisation List
        </b-link>
        for other organisations.
      </div>
    </b-alert>
    <template #title>
      <feather-icon
        icon="UserIcon"
        size="16"
        class="mr-0 mr-sm-50"
      />
      <span class="d-none d-sm-inline">Informatie</span>
    </template>
    <organisation-edit-tab-account
      v-if="organisationData"
      :organisation-data="organisationData"
      :status-options="statusOptions"
      :country-options="countryOptions"
      :district-options="districtOptions"
      :external-finance-type-options="externalFinanceTypeOptions"
      :resolve-district-for-country="resolveDistrictForCountry"
      class="mt-2 pt-75"
      @update-organisation="updateOrganisation"
    />
  </component>
</template>

<script>
import {
  BTab, BTabs, BCard, BAlert, BLink,
} from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import store from '@/store'
import router from '@/router'
import OrganisationEditTabAccount from '@/views/pages/organisations/organisation-edit/OrganisationEditTabAccount.vue'
import organisationStoreModule from '@/views/pages/organisations/organisationsStoreModule'
import useOrganisationsList from '@/views/pages/organisations/useOrganisationsList'

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    OrganisationEditTabAccount,
  },
  methods: {
    updateOrganisation(organisationData) {
      store.dispatch('app-organisation-edit/updateOrganisation', organisationData)
        .then(() => {
          router.push({ name: 'apps-organisations' })
        })
        .catch(error => {
          this.$refs.organisationAddForm.setErrors(error.response.data.error)
        })
    },
  },
  setup() {
    const organisationData = ref(null)

    const USER_APP_STORE_MODULE_NAME = 'app-organisation-edit'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, organisationStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    store.dispatch('app-organisation-edit/fetchOrganisation', { id: router.currentRoute.params.id })
      .then(response => { organisationData.value = response.data })
      .catch(error => {
        if (error.response.status === 404) {
          organisationData.value = undefined
        }
      })

    const {
      statusOptions,
      countryOptions,
      districtOptions,
      resolveDistrictForCountry,
      externalFinanceTypeOptions,
    } = useOrganisationsList()

    return {
      statusOptions,
      countryOptions,
      districtOptions,
      resolveDistrictForCountry,
      organisationData,
      externalFinanceTypeOptions,
    }
  },
}
</script>

<style>

</style>
