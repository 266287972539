<template>
  <div>
    <!-- company Info: Input Fields -->
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-form
        class="mb-2"
        @submit.prevent="handleSubmit(onSubmit)"
      >
        <!-- Header: company Info -->
        <div class="d-flex mb-1">
          <feather-icon
              icon="UserIcon"
              size="19"
          />
          <h4 class="mb-0 ml-50">
            Bedrijfsinformatie
          </h4>
        </div>
        <b-row>
          <b-col
            cols="12"
            md="2"
          >
            <b-form-group
              label="ID"
              label-for="id"
            >
              <b-form-input
                id="id"
                v-model="organisationData.id"
                readonly="readonly"
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            md="3"
          >
            <validation-provider
                #default="validationContext"
                name="name"
                rules="required"
            >
              <b-form-group
                label="Naam"
                label-for="name"
              >
                <b-form-input
                  id="name"
                  v-model="organisationData.name"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            cols="12"
            md="3"
          >
            <validation-provider
                #default="validationContext"
                name="status"
                rules="required"
            >
              <b-form-group
                label="Status"
                label-for="status"
                :state="getValidationState(validationContext)"
              >
                <v-select
                  v-model="organisationData.active"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="statusOptions"
                  :reduce="val => val.value"
                  :clearable="false"
                  id="status"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!-- Field: Email -->
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
                #default="validationContext"
                name="email"
                rules="required|email"
            >
              <b-form-group
                label="Email"
                label-for="email"
              >
                <b-form-input
                  id="email"
                  v-model="organisationData.email"
                  type="email"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: Mobile -->
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <validation-provider
                #default="validationContext"
                name="phoneNumber"
                rules="required|integer"
            >
              <b-form-group
                label="Telefoonnummer"
                label-for="phoneNumber"
              >
                <b-form-input
                  id="phoneNumber"
                  v-model="organisationData.phoneNumber"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
              cols="12"
              md="4"
          >
            <validation-provider
                #default="validationContext"
                name="hasMainCompany"
                rules="required"
            >
              <b-form-group
                  label="Type"
                  label-for="type"
                  :state="getValidationState(validationContext)"
              >
                <v-select
                    v-model="organisationData.type"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="typeOptions"
                    :reduce="val => val.value"
                    :clearable="false"
                    id="type"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
              v-if="organisationData.type === 2"
              cols="12"
              md="4"
          >
            <validation-provider
                #default="validationContext"
                name="parentCompanyID"
                rules="required"
            >
              <b-form-group
                  label="Hoofdorganisatie"
                  label-for="parentCompanyID"
                  :state="getValidationState(validationContext)"
              >
                <v-select
                    v-model="organisationData.parentCompanyID"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="companyData"
                    :reduce="val => val.id"
                    label="name"
                    :clearable="false"
                    id="parentCompanyID"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>

        <div class="d-flex mt-2 mb-1">
          <feather-icon
              icon="CreditCardIcon"
              size="19"
          />
          <h4 class="mb-0 ml-50">
            Financieel
          </h4>
        </div>
        <b-row>
          <b-col
              cols="12"
              md="4"
          >
            <b-form-group
                label="Commisieplan"
                label-for="commissionPlanID"
            >
              <v-select
                  v-model="organisationData.commissionPlanID"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="commissionPlanOptions"
                  :reduce="val => val.id"
                  label="name"
                  :clearable="true"
                  id="commissionPlanID"
              />
            </b-form-group>
          </b-col>
          <b-col
              cols="12"
              md="6"
              lg="4"
          >
            <validation-provider
                #default="validationContext"
                name="externalFinanceIdentifier"
                rules="required"
            >
              <b-form-group
                  label="Agentnummer"
                  label-for="externalFinanceIdentifier"
              >
                <b-form-input
                    id="externalFinanceIdentifier"
                    v-model="organisationData.externalFinanceIdentifier"
                    :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
              cols="12"
              md="4"
          >
            <validation-provider
                #default="validationContext"
                name="externalFinanceType"
                rules="required"
            >
              <b-form-group
                  label="Agenttype"
                  label-for="externalFinanceType"
                  :state="getValidationState(validationContext)"
              >
                <v-select
                    v-model="organisationData.externalFinanceType"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="externalFinanceTypeOptions"
                    :reduce="val => val.value"
                    label="label"
                    :clearable="true"
                    id="externalFinanceType"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
              cols="12"
              md="6"
              lg="4"
          >
            <validation-provider
                #default="validationContext"
                name="creditorNumber"
                rules="required"
            >
              <b-form-group
                  label="Crediteurnummer"
                  label-for="creditorNumber"
              >
                <b-form-input
                    id="creditorNumber"
                    v-model="organisationData.creditorNumber"
                    :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
              cols="12"
              md="6"
              lg="4"
          >
            <validation-provider
                #default="validationContext"
                name="debtorNumber"
                rules="required"
            >
              <b-form-group
                  label="Debiteurnummer"
                  label-for="debtorNumber"
              >
                <b-form-input
                    id="debtorNumber"
                    v-model="organisationData.debtorNumber"
                    :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
              cols="12"
          >
            <validation-provider
                #default="validationContext"
                name="bankAccount"
                rules="required"
            >
              <b-form-group
                  label="Rekeningnummers voor op de documentatie"
                  label-for="bankAccount"
              >
                <b-form-input
                    id="bankAccount"
                    v-model="organisationData.bankAccount"
                    :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>

        <!-- Header: Personal Info -->
        <div class="d-flex mt-2">
          <feather-icon
            icon="MapPinIcon"
            size="19"
          />
          <h4 class="mb-0 ml-50">
            Adres
          </h4>
        </div>

        <!-- Form: Personal Info Form -->
        <b-row class="mt-1">

          <!-- Field: Address Line 1 -->
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <validation-provider
                #default="validationContext"
                name="street"
                rules="required"
            >
              <b-form-group
                label="Straatnaam"
                label-for="street"
              >
                <b-form-input
                  id="street"
                  v-model="organisationData.street"
                  placeholder=""
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: Housenumber -->
          <b-col
            cols="12"
            md="3"
            lg="2"
          >
            <validation-provider
                #default="validationContext"
                name="housenumber"
                rules="required"
            >
              <b-form-group
                label="Huisnummer"
                label-for="housenumber"
              >
                <b-form-input
                  id="housenumber"
                  v-model="organisationData.housenumber"
                  type="text"
                  placeholder=""
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>

        <b-row class="mt-1">
          <!-- Field: City -->
          <b-col
            cols="12"
            md="4"
            lg=""
          >
            <validation-provider
                #default="validationContext"
                name="city"
                rules="required"
            >
              <b-form-group
                label="Plaats"
                label-for="city"
              >
                <b-form-input
                  id="city"
                  v-model="organisationData.city"
                  placeholder=""
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: District -->
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
                #default="validationContext"
                name="district"
                rules="required"
            >
              <b-form-group
                label="District"
                label-for="district"
                :state="getValidationState(validationContext)"
              >
                <v-select
                  v-model="organisationData.district"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="districtOptions"
                  :reduce="val => val.value"
                  :clearable="false"
                  id="district"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
                #default="validationContext"
                name="country"
                rules="required"
            >
              <b-form-group
                label="Country"
                label-for="country"
                :state="getValidationState(validationContext)"
              >
                <v-select
                  v-model="organisationData.country"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="countryOptions"
                  :reduce="val => val.value"
                  :clearable="false"
                  input-id="country"
                  @change="countryChanged(organisationData.country)"
                  @input="countryChanged(organisationData.country)"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col
          cols="12"
          >
            <!-- Header: company Info -->
            <div class="d-flex mt-1 mb-1">
              <feather-icon
                  icon="UserIcon"
                  size="19"
              />
              <h4 class="mb-0 ml-50">
                Productcategorieën
              </h4>
            </div>
            <b-form-group label="Toegankelijke productcategorieën">
              <v-select
                  v-model="organisationData.productCategories"
                  dir="ltr"
                  multiple
                  label="name"
                  :reduce="val => val.id"
                  :options="productCategorieData"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col>
            <b-button
              variant="secondary"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              type="button"
              :to="{ name: 'apps-organisations' }"
            >
              terug
            </b-button>
            <b-button
              v-if="$can('update', 'organisation')"
              variant="primary"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              type="submit"
            >
              Opslaan
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import {
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BFormInvalidFeedback,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import { required, email, integer } from '@validations'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import router from '@/router'
import useOrganisationsList from '@/views/pages/organisations/useOrganisationsList'

const toast = useToast()

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BFormInvalidFeedback,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    organisationData: {
      type: Object,
      required: true,
      default: () => {},
    },
    statusOptions: {
      type: Array,
      required: true,
      default: () => ([]),
    },
    countryOptions: {
      type: Array,
      required: true,
      default: () => ([]),
    },
    districtOptions: {
      type: Array,
      required: true,
      default: () => ([]),
    },
    externalFinanceTypeOptions: {
      type: Array,
      required: true,
      default: () => ([]),
    },
    resolveDistrictForCountry: {
      type: Function,
    },
    updateOrganisation: {
      type: Function,
    },
  },
  data() {
    return {
      productCategorieData: [],
      companyData: [],
      value: [],
      commissionPlanOptions: [],
    }
  },
  computed: {
    availableOptions() {
      return this.productCategorieData.filter(opt => this.value.indexOf(opt) === -1)
    },
  },
  beforeMount() {
    store
      .dispatch('app-organisation-edit/fetchProductCategories', {
        sort: 'name',
      })
      .then(response => {
        this.productCategorieData = response.data.items
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error bij het ophalen van de product categorieen',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })

    store
      .dispatch('app-organisation-edit/fetchOrganisations', {
        sort: 'name',
      })
      .then(response => {
        this.companyData = response.data.items.filter(opt => String(opt.id) !== router.currentRoute.params.id)
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error bij het ophalen van de organisaties',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })

    store
      .dispatch('app-organisation-edit/fetchCommissionPlans', {
        sort: 'name',
      })
      .then(response => {
        this.commissionPlanOptions = response.data.items
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error bij het ophalen van de commisieplannen',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },
  methods: {
    countryChanged(countryCode) {
      this.organisationData.district = ''
      this.districtOptions = this.resolveDistrictForCountry(countryCode)
    },
    onSubmit() {
      this.$emit('update-organisation', this.organisationData)
    },
  },
  setup() {
    const {
      typeOptions,
    } = useOrganisationsList()
    const {
      refFormObserver,
      getValidationState,
    } = formValidation()

    return {
      refFormObserver,
      getValidationState,
      avatarText,
      typeOptions,
      required,
      email,
      integer,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.invalid{
  .card-title{
    color: #ea5455;
  }
}
</style>
